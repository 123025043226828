.loader {
    width: 150px;
    height: 150px;
    margin-top: 5rem;
    background-image: #212121;
    box-shadow: 0px 5px 10px #7fe8ff, 0px -5px 10px #99ff33, 5px 0px 10px#4cffb4, -5px 0px 10px #4cffb4,
    inset 0px 5px 10px #99ff33, inset 0px -5px 10px #7fe8ff, inset 5px 0px 10px#4cffb4, inset -5px 0px 10px #4cffb4;
    border-radius: 20em;
    position: relative;
    animation: rotate 1s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  