/* For Webkit Browsers (Chrome, Safari) */
.menu-list::-webkit-scrollbar {
    width: 12px;
}

.menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.menu-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
}

.menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* For Firefox */
.menu-list {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.menu-list:hover {
    scrollbar-color: #555 #f1f1f1;
}